@if(template().child){

<div
  class="dark:bg-slate-400 dark:text-white shadow-md flex flex-col items-center justify-center rounded-2xl gap-4 p-6 min-h-[auto] w-full h-full">
  <div class="w-8 h-8 text-center flex-1">
    <div role="img" [appRenderSvg]="template().svg" [style.color]="template().hexColor || 'currentColor'"></div>
  </div>
  <div class="flex-1">
    <p>{{ template().label }}</p>

    <br>

    @for (ch of template().child; track $index) {

    <div>
      <button (click)="loadTemplateChild(ch)" type="button">
        <div class="flex">
          <i role="img" [appRenderSvg]="ch.svg" [style.color]="ch.hexColor || 'currentColor'"></i>
          <span class="underline">{{ ch.label }}</span>
        </div>
      </button>
    </div>

    }

  </div>
</div>


}@else{
<button (click)="loadTemplate()" type="button"
  class="dark:bg-slate-400 dark:hover:bg-slate-700 hover:bg-slate-100 dark:text-white shadow-md flex flex-col items-center justify-center rounded-2xl gap-4 p-6 min-h-[auto] w-full h-full">
  <div class="w-8 h-8 text-center flex-1">
    <div role="img" [appRenderSvg]="template().svg" [style.color]="template().hexColor || 'currentColor'"></div>
  </div>
  <div class="flex-1">
    <p>{{ template().label }}</p>
  </div>
</button>



}