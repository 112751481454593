import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core'; 
import { NpmChatStore } from '../../store';
import { Template } from '../../models';
import { RenderSvgDirective } from '../../directives';

@Component({
  selector: 'app-template',
  standalone: true,
  imports: [RenderSvgDirective],
  templateUrl: './template.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateComponent {
  readonly #npmChatStore = inject(NpmChatStore);
  template = input.required<Template>();

  loadTemplate() {
    this.#npmChatStore.newUserMessage(this.template().prompt);
  }  
  
  loadTemplateChild(child: Template) {
    this.#npmChatStore.newUserMessage(child.prompt);
  }
}
