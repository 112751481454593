<article class="chat" [class.chat-start]="!isUser()" [class.chat-end]="isUser()">
  <div class="chat-image avatar">
    <div class="w-10 rounded-full">

      @if (!isUser()) {
      <i [class]="image()" class="text-3xl"></i>
      }

    </div>
  </div>
  <div class="chat-header">
    @if (!isUser()) {
    {{ sender() }}
    }

    <time class="text-xs opacity-50">{{ time() | date : "shortTime" }}</time>
  </div>
  <div class="chat-bubble" [class.chat-bubble-primary]="isUser()">
    @if(hasMessage()){

    <!-- <pre class="whitespace-break-spaces">{{ content() }}</pre> -->

    <div markdown [data]="content()">
    </div>


    } @else {
    <span class="loading loading-dots loading-sm"></span>
    }
  </div>
  <div class="chat-footer opacity-50">
    @if (tokens()) {
    {{ tokens() }}
    } @else { calculando tokens... }
  </div>
</article>