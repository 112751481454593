import { Component, inject } from "@angular/core";

import { WebllmService } from "../services";
import { NpmChatStore } from "../store";
import { SPlusOption } from "@saludplus/forms";
import { MermaidAPI } from 'ngx-markdown';



@Component({
  selector: "app-jarvis",
  templateUrl: "./jarvis.component.html",
  styleUrls: ["./jarvis.component.scss"]
})
export class JarvisComponent {

  readonly #webllmService = inject(WebllmService);
  readonly #npmChatStore = inject(NpmChatStore);
  readonly #systemMessage = this.#npmChatStore.selectSystemMessage;

  hasMessages = this.#npmChatStore.hasMessages;
  isLlmLoaded = this.#npmChatStore.isLlmLoaded;


  options: SPlusOption[] = [
    {
      id: "new-chat",
      icon: "fa-solid fa-plus",
      text: "Nuevo Chat",
      action: () => {
        //if (!this.#npmChatStore.selectIsBusy) {
          this.#npmChatStore.clearMessages();
        //}
      },
    },
  ]

  constructor() {
    this.#webllmService.initialize(this.#systemMessage());
  }


}
