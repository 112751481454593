import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component";  
import { JarvisComponent } from "./components/jarvis.component"; 

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Jarvis Listado" },
  },
  { path: "", component: JarvisComponent },
  { path: ":id", component: JarvisComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JarvisRoutingModule {
 
  //Routing principal
  public static RoutingJarvis = {
    path: "developer/jarvis",
    loadChildren: () =>
      import("./jarvis.module").then(
        (m) => m.JarvisModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Jarvis`,
      icon: "fa-duotone fa-microchip-ai" 
    },
  };
}
