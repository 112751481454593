<div class="flex flex-col justify-center h-full">
  @if (isLlmLoaded() && !isLlmBusy()) {
  <div class="flex gap-2 items-center relative w-full">
    <textarea rows="1"
      class="leading-8 text-base flex-1 min-h-12 max-h-48 resize-none rounded-3xl pr-10 p-2 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
      [placeholder]="promptPLaceholder" #textareaRef [ngModel]="message()"
      (ngModelChange)="onMessageChange(textareaRef, $event)" (keydown.enter)="submitMessage($event)"></textarea>

    <button (click)="submitMessage($event)"
      class="absolute bottom-2 right-2 mb-1 me-1 flex h-8 w-8 items-center justify-center rounded-full bg-blue-900 text-white transition-colors hover:opacity-70 focus-visible:outline-none focus-visible:outline-black disabled:bg-[#D7D7D7] disabled:text-[#f4f4f4] disabled:hover:opacity-100 dark:bg-white dark:text-black dark:focus-visible:outline-white disabled:dark:bg-token-text-quaternary dark:disabled:text-token-main-surface-secondary"
      [disabled]="!hasMessage()"> <i class="fa-solid fa-arrow-up"></i>
    </button>

  </div>
  } @else {
  <div class="skeleton min-h-12 rounded-3xl"></div>
  }
</div>