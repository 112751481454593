import { Injectable, Signal, signal } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LLMReply, LLMReport, LLMService, Message, Messages } from '../models';
import { HttpClient } from '@angular/common/http'; 

@Injectable({
  providedIn: 'root',
})
export class WebllmService implements LLMService {
  /*private readonly apiUrl = 'http://localhost:1234/v1/chat/completions';
  private readonly apiKey = ''; */

  private readonly apiUrl = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash-latest:generateContent';
  private readonly apiKey = 'AIzaSyBZw2AWPkiQrOWjCqFgbSBEHkZvH9p_0Ks';

  readonly #progressReport = signal<LLMReport>({
    progress: 0,
    text: '',
    timeElapsed: 0,
    hasEngine: false,
  });

  #systemMessage!: Message;

  constructor(private http: HttpClient) {}

  llmReport: Signal<LLMReport> = this.#progressReport.asReadonly();

  getChatReply(messages: Messages): Observable<LLMReply> {
    const newMessages = [this.#systemMessage, ...messages];
    const llmReply = new Subject<LLMReply>();
    this.#chatCompletionReplay(newMessages, llmReply);
    return llmReply.asObservable();
  }

  initialize(systemMessage: Message): void {
    this.#systemMessage = systemMessage;
    this.#initialize();
  }

  async #initialize() {
    // Simulamos la inicialización para mantener la estructura similar
    setTimeout(() => {
      this.#progressReport.update((currentReport) => ({
        ...currentReport,
        progress: 100,
        hasEngine: true,
      }));
    }, 1000);
  }

  #onNewReport(report: Partial<LLMReport>): void {
    this.#progressReport.update((currentReport) => ({
      ...currentReport,
      ...report,
    }));
  }

  async #chatCompletionReplay(messages: Messages, llmReply: Subject<LLMReply>) {
  /*  const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}`
    };*/

    const headers = {
      'Content-Type': 'application/json',
    };

  /*  const body = {
      model: 'lmstudio-community/Meta-Llama-3.1-8B-Instruct-GGUF/Meta-Llama-3.1-8B-Instruct-Q6_K.gguf', // O el modelo que prefieras
      messages: messages.map(msg => ({ role: msg.role, content: msg.content })),
      stream: true
    };*/

    const body = {
      contents: messages.map(msg => ({
        role: msg.role,
        parts: [{ text: msg.content }]
      })),
      generationConfig: {
        temperature: 1,
        topK: 64,
        topP: 0.95,
        maxOutputTokens: 8192,
        responseMimeType: "text/plain"
      }
    };


    try {
      const response = await this.http.post(`${this.apiUrl}?key=${this.apiKey}`, body, { headers, responseType: 'text' }).toPromise();
      const jsonResponse = JSON.parse(response as string);

      if (jsonResponse.candidates && jsonResponse.candidates.length > 0) {
        const reply = jsonResponse.candidates[0].content.parts[0].text;
        llmReply.next({ content: reply });
        this.#onNewReport({ text: reply });

        // Simulamos el uso de tokens ya que Gemini no proporciona esta información directamente
        const usage = {
          completion_tokens: Math.floor(reply.length / 4),
          prompt_tokens: Math.floor(messages.reduce((acc, msg) => acc + msg.content.length, 0) / 4),
          total_tokens: 0
        };
        usage.total_tokens = usage.completion_tokens + usage.prompt_tokens;

        llmReply.next({
          content: reply,
          usage: {
            completionTokens: usage.completion_tokens,
            promptTokens: usage.prompt_tokens,
            totalTokens: usage.total_tokens,
          },
        });
      }
      
      llmReply.complete();
    } catch (error) {
      console.error('Error in Gemini API call', error);
      llmReply.error(error);
    }

   /* try {
      const response = await fetch(this.apiUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Response body is null');
      }

      let reply = '';
      let decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ') && line !== 'data: [DONE]') {
            const data = JSON.parse(line.slice(6));
            if (data.choices && data.choices[0].delta.content) {
              reply += data.choices[0].delta.content;
              llmReply.next({ content: reply });
              this.#onNewReport({ text: reply });
            }
          }
        }
      }

      // Simulamos el uso de tokens ya que la API en streaming no proporciona esta información
      const usage = {
        completion_tokens: Math.floor(reply.length / 4),
        prompt_tokens: Math.floor(messages.reduce((acc, msg) => acc + msg.content.length, 0) / 4),
        total_tokens: 0
      };
      usage.total_tokens = usage.completion_tokens + usage.prompt_tokens;

      llmReply.next({
        content: reply,
        usage: {
          completionTokens: usage.completion_tokens,
          promptTokens: usage.prompt_tokens,
          totalTokens: usage.total_tokens,
        },
      });
      llmReply.complete();
    } catch (error) {
      console.error('Error in OpenAI API call', error);
      llmReply.error(error);
    }*/
  }
}