<splus-forms id="JarvisPage"  >

<form >

    <splus-forms-header>

      <splus-forms-main-title title="J.A.R.V.I.S." icon="fa-duotone fa-microchip-ai" [applyTransformation]="false">
      </splus-forms-main-title>


      <splus-forms-toolbar [options]="options"></splus-forms-toolbar>
    </splus-forms-header>
 </form>
</splus-forms>
 
<main class="grid grid-rows-[1fr_100px] overflow-hidden">
  <section #scrollable class="overflow-y-auto h-[calc(100svh-300px)]">
    @if (!isLlmLoaded()) {
    <app-llm-progress />
    <div
      class="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-full p-4"
    >
      <app-brand-details />
    </div>
    } @else { @if (hasMessages()) {
    <div class="h-full p-4 mx-auto max-w-screen-lg">
      <app-messages [scrollable]="scrollable" />
    </div>
    } @else {
    <div class="grid place-items-center h-full gap-8 mx-auto max-w-screen-lg">
      <div class="flex flex-col gap-8 p-4">
        <app-brand-details />
        <app-templates />
      </div>
    </div>

    } }
  </section>
  <section class="px-4 w-full h-full mx-auto max-w-screen-lg">
    <app-prompt />
  </section>
</main>


