<div class="text-center"> 
  <div class="mt-2">
    <div>

      <i class="fa-solid fa-microchip-ai text-4xl text-developer"></i>
    </div>
    
    <strong class="text-success">J.A.R.V.I.S.</strong>  
    es una IA y un modelo de lenguaje grande (LLM) <br>
    optimizado que ayudará a la creación de código para SaludPlus.
  </div>
</div>
