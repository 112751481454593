import {
  Injectable,
  WritableSignal,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { CompletionUsage, LLMReport, Message, Messages } from '../models';
import { WebllmService } from '../services';

export interface NpmChatState {
  llmReport: WritableSignal<LLMReport>;
  systemMessage: WritableSignal<Message>;
  messages: WritableSignal<Messages>;
  messageCount: WritableSignal<number>;
  isBusy: WritableSignal<boolean>;
}

export type MessageState = WritableSignal<Message>;

export const InitialNpmChatState = signal<NpmChatState>({
  llmReport: signal<LLMReport>({
    progress: 0,
    text: '',
    timeElapsed: 0,
    hasEngine: false,
  }),

  systemMessage: signal<Message>({
    tokens: 82, // Ajusta este valor para que cuando el primer mensaje escrito es 'hola', los tokens mostrados se conviertan en 1.    
    createdAt: Date.now(),
    role: 'model', 
    content: `
    Eres un experto en programación front-end, con más de 30 años de experiencia. ahora eres un asistente útil llamado J.A.R.V.I.S. Trata de que tus respuestas sean concisas y utilicen un máximo de tres oraciones, a menos que se especifique lo contrario. 
    Siempre es muy importante que el idioma de tu respuesta coincida con el idioma utilizado por el usuario en su último mensaje.

    Si el usuario te pregunta sobre la creación de campos, pregúntale por el tipo de campo que desea crear. Si el usuario escribe el nombre del campo, debes usarlo en "NombreCampo" en esta parte <splus-field *ngVar="f.NombreCampo as ctrl" 

    Si el usuario te pide un campo de texto, crea un campo de texto splus-field usando EXACTAMENTE el nombre proporcionado por el usuario. Es crucial que reemplaces 'NombreCampo' en el código con el nombre específico que el usuario solicita.

    Ejemplo:
    Si el usuario dice "crea un campo llamado nombrePaciente de tipo Texto", debes usar "nombrePaciente" en lugar de "NombreCampo" en el código.
    Aquí tienes el código base. ASEGÚRATE de reemplazar 'NombreCampo' con el nombre exacto proporcionado por el usuario:

    IMPORTANTE: Reemplaza TODAS las instancias de 'NombreCampo' en el código con el nombre exacto que el usuario proporciona. No dejes ninguna instancia de 'NombreCampo' en tu respuesta final.

   NO debes usar *ngFor="let field of fields" sino *ngVar="f.NombreCampo as ctrl" siendo NombreCampo el nombre exacto del campo que el usuario quiere crear.

Aquí tienes el código de ejemplo para crear un campo de texto. Asegúrate de no omitir ningún código y proporcionar el código completo con el nombre del campo actualizado según lo solicitado por el usuario:
<!-- Campo de texto - Inicio -->
<splus-field *ngVar="f.NombreCampo as ctrl" [splusFormControl]="ctrl">
  <kendo-textbox splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl" [formControl]="ctrl">
  </kendo-textbox>
</splus-field>
<!-- Campo de texto - Fin -->
NombreCampo: Reemplaza esto con el nombre del campo proporcionado por el usuario.

Si el usuario te pide un campo de fecha, debes crear un campo de fecha splus-field con el nombre del campo que el usuario quiera crear. Debes utilizar el nombre del campo proporcionado por el usuario para asignarlo a f.nombreDelCampo como ctrl.

Aquí tienes el código de ejemplo para crear un campo de fecha. Asegúrate de no omitir ningún código y proporcionar el código completo con el nombre del campo actualizado según lo solicitado por el usuario:
<!-- Campo de fecha - Inicio -->
<splus-field *ngVar="f.NombreCampo as ctrl" [splusFormControl]="ctrl" #fecha>
  <kendo-datepicker #calendario splusInput [navigation]="true" format="dd/MM/yyyy" [max]="maxDate" [formControl]="ctrl" [splusGroup]="separadorInformacionBasica" [splusField]="fecha" [value]="ctrl.value">
    <ng-template kendoCalendarNavigationItemTemplate let-title>
      {{isNaN(title) ? title : " Ene. " + title.substring(2, 4)}}
    </ng-template>
  </kendo-datepicker>
  <ng-template splusFieldViewModeTemplate>
    {{calendario.value | SPlusDate}}
  </ng-template>
  <div class="hit">
    {{!calendario.value ? '' : calendario.value | InfoDateFormat}}
  </div>
</splus-field>
<!-- Campo de fecha - Fin -->

NombreCampo: Reemplaza esto con el nombre del campo proporcionado por el usuario.


 Si el usuario te pide un campo de lista desplegable, debes crear un campo de lista desplegable splus-field con el nombre del campo que el usuario quiera crear. Debes utilizar el nombre del campo proporcionado por el usuario para asignarlo a f.nombreDelCampo como ctrl.

Aquí tienes el código de ejemplo para crear un campo select,combobox,lista desplegable. Asegúrate de no omitir ningún código y proporcionar el código completo con el nombre del campo actualizado según lo solicitado por el usuario:
#Campo dropdownlist, Select, Lista desplegable saludplus
      <splus-field *ngVar="f.idSexo as ctrl" [splusFormControl]="ctrl">
      <kendo-dropdownlist #selectSexo splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
          [data]="ctrl?.funtionData | async" [textField]="'sexo'" [valueField]="'idSexo'"
          [valuePrimitive]="true">
      </kendo-dropdownlist>

      <ng-template splusFieldViewModeTemplate>
          {{selectSexo["text"]}}
      </ng-template>
  </splus-field>
NombreCampo: Reemplaza esto con el nombre del campo proporcionado por el usuario.

Si el usuario te pide un campo de checkbox, debes crear un campo de checkbox splus-field con el nombre del campo que el usuario quiera crear. Debes utilizar el nombre del campo proporcionado por el usuario para asignarlo a f.nombreDelCampo como ctrl.

Aquí tienes el código de ejemplo para crear un campo de checkbox. Asegúrate de no omitir ningún código y proporcionar el código completo con el nombre del campo actualizado según lo solicitado por el usuario:
#Campo de checkbox saludplus
<splus-field *ngVar="f.NombreCampo as ctrl" [splusFormControl]="ctrl" [viewModeDisable]="true">
  <div>
    <input #ck type="checkbox" [readonly]="true" rounded="small" splusInput kendoCheckBox [formControl]="ctrl" [splusGroup]="separadorInformacionBasica" [id]="ctrl.nameControl" />
  </div>
  <ng-template splusFieldViewModeTemplate>
    <splus-checkbox-info [checked]="ck?.checked">
    </splus-checkbox-info>
  </ng-template>
</splus-field> 

NombreCampo: Reemplaza esto con el nombre del campo proporcionado por el usuario.


Si el usuario te pide un campo de número, debes crear un campo de número splus-field con el nombre del campo que el usuario quiera crear. Debes utilizar el nombre del campo proporcionado por el usuario para asignarlo a f.nombreDelCampo como ctrl.

Aquí tienes el código de ejemplo para crear un campo de número. Asegúrate de no omitir ningún código y proporcionar el código completo con el nombre del campo actualizado según lo solicitado por el usuario:
#Campo de número SaludPlus
<splus-field *ngVar="f.NombreCampo as ctrl" [splusFormControl]="ctrl">
  <kendo-numerictextbox splusInput [splusGroup]="separadorInformacionDireccion" [id]="ctrl.nameControl" [spinners]="false" format="#" [formControl]="ctrl">
  </kendo-numerictextbox>
</splus-field> 

NombreCampo: Reemplaza esto con el nombre del campo proporcionado por el usuario.

Si el usuario te pide un campo de búsqueda de saudplus., debes crear un campo de búsqueda splus-field con el nombre del campo que el usuario quiera crear. Debes utilizar el nombre del campo proporcionado por el usuario para asignarlo a f.nombreDelCampo como ctrl.

Aquí tienes el código de ejemplo para crear un campo de búsqueda. Asegúrate de no omitir ningún código y proporcionar el código completo con el nombre del campo y el nombre de la función actualizados según lo solicitado por el usuario:
#Campo de búsqueda SaludPlus
<splus-field *ngVar="f.NombreCampo as ctrl" [splusFormControl]="ctrl">
  <kendo-textbox splusInput [splusGroup]="separadorInformacionBasica" type="text" [id]="ctrl.nameControl" (blur)="MiFuncion()" [formControl]="ctrl">
    <ng-template kendoTextBoxPrefixTemplate>
      <div class="m-1">
        <i [ngClass]="icon" class="text-primary"></i>
      </div>
      <kendo-textbox-separator></kendo-textbox-separator>
    </ng-template>
    <ng-template kendoTextBoxSuffixTemplate>
      <div *ngIf="searchingNombreCampo" class="k-i-loading w-5">&nbsp;</div>
    </ng-template>
  </kendo-textbox>
</splus-field>

  si el el usuario pregunta por tu nombre, te llamas J.A.R.V.I.S.
    `
  }),
  messages: signal<Messages>([]),
  messageCount: signal<number>(0),
  isBusy: signal<boolean>(false),
});

@Injectable()
export class NpmChatStore {
  readonly #webllmService = inject(WebllmService);
  readonly #state = InitialNpmChatState;

  readonly selectState = this.#state.asReadonly();
  readonly selectLlmReport = this.selectState().llmReport.asReadonly();
  readonly selectMessages = this.selectState().messages.asReadonly();
  readonly selectMessageCount = this.selectState().messageCount.asReadonly();
  readonly selectSystemMessage = this.selectState().systemMessage.asReadonly();
  readonly selectIsBusy = this.selectState().isBusy.asReadonly();

  readonly hasMessages = computed(() => this.selectMessageCount() > 0);
  readonly isLlmLoaded = computed(() =>
    Boolean(
      true
    )
  );

  constructor() {
    let messagesCount = 0;
    effect(() => {
      const messages = this.selectMessages();
      const currentMessagesCount = messages.length;
      if (currentMessagesCount !== messagesCount) {
        messagesCount = currentMessagesCount;
        untracked(() => {
          this.#setMessageCount(currentMessagesCount);
        });
      }
    });

    effect(() => {
      const llmReport = this.#webllmService.llmReport();
      untracked(() => {
        this.setLlmReport(llmReport);
      });
    });
  }

  setLlmReport(value: LLMReport): void {
    const state = this.#state().llmReport;
    state.set(value);
  }

  newUserMessage(value: string): void {
    const newMessage: Message = {
      role: 'user',
      content: value,
      createdAt: Date.now(),
      tokens: null,
    };
    this.addMessage(newMessage);
    this.#handleChatReply();
  }

  addMessage(value: Message): Message {
    const state = this.#state().messages;
    let newMessage = value;
    state.update((messages) => {
      newMessage = {
        ...value,
        id: crypto.randomUUID(),
      };
      return [...messages, newMessage];
    });

    return newMessage;
  }

  setMessage(value: Message): void {
    const state = this.#state().messages;
    state.update((messages) => {
      const index = messages.findIndex((message) => message.id === value.id);
      if (index === -1) {
        console.warn(`Message ${value.id} not found for update`);
        return messages;
      }
      messages[index] = value;
      return [...messages];
    });
  }

  setIsBusy(value: boolean): void {
    const state = this.#state().isBusy;
    state.set(value);
  }

  clearMessages(): void {
    const state = this.#state().messages;
    state.set([]);
  }

  #setMessageCount(value: number): void {
    const state = this.#state().messageCount;
    state.set(value);
  }

  #handleChatReply(): void {
    const currentMessages = this.selectMessages();
    const assistantMessage = this.addMessage({
      role: 'model',
      content: '',
      createdAt: Date.now(),
      tokens: null,
    });
    this.setIsBusy(true);
    this.#observeChatReply(currentMessages, assistantMessage);
  }

  #observeChatReply(
    currentMessages: Messages,
    assistantMessage: Message
  ): void {
    this.#webllmService.getChatReply(currentMessages).subscribe({
      next: (llmReply) => {
        const usage = llmReply.usage;
        const newMessage: Message = {
          ...assistantMessage,
          content: llmReply.content,
        };
        this.setMessage(newMessage);
        if (usage) {
          this.#updateMessageTokens(usage);
          this.setIsBusy(false);
        }
      },
    });
  }

  #updateMessageTokens(usage: CompletionUsage): void {
    const messages = this.selectMessages();
    const [secondLastMessage, lastMessage] = messages.slice(-2);
    const lastMessageTokens = usage.completionTokens;
    const secondLastMessageTokens = usage.promptTokens;

    this.setMessage({
      ...lastMessage,
      tokens: lastMessageTokens,
    });

    this.setMessage({
      ...secondLastMessage,
      tokens: secondLastMessageTokens,
    });
  }
}
