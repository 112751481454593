import { ChangeDetectionStrategy, Component } from '@angular/core';  

@Component({
  selector: 'app-brand-details',
  standalone: true,
  imports: [],
  templateUrl: './brand-details.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandDetailsComponent {}
